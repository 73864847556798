<template>
  <!-- 关于我们 -->
  <div class="aboutUs">
    <div class="center-container">
      <div class="left-content">
        <div class="s-title s-title-b">
          <span class="decoration"></span>
          <span class="title">关于我们</span>
        </div>
        <div class="change">
          <div
            v-for="(item,index) in types"
            :key="index"
            :class="{'active': currentIndex == index}"
            class="change-item"
            @click="changeType(item, index)">
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
      <div class="right-content">
        <div class="s-title s-title-b">
          <span >当前位置：</span>
          <span >关于我们</span> >
          <span>{{currentType.name}}</span>
          <el-button class="ques" type="primary" v-if="currentType.type === 3" @click="question">我要提问</el-button>
        </div>
        <div class="info" v-if="currentType.type === 0">
          <div class="docs">
            <div class="docs-item profile">
              <span>
                <img src="imgs/icon-decoration.png" alt="">
                <span>{{company.companyProfile}}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="connect" v-if="currentType.type === 1">
          <div class="cen">
            <div class="code">
              <img :src="company.corporateQRcode" alt="">
            </div>
            <p>注册地址：{{company.registeredAddress}} </p>
            <p>市内运营中心：{{company.address}} </p>
            <p>联系电话：{{company.contract}}</p>
          </div>
        </div>
        <div class="table-card" v-if="currentType.type === 3">
          <div class="info">
            <div class="docs">
              <div class="docs-item" v-for="item in msgs" :key="item.askContent" @click="goDetail(item)">
                <span>
                  <img src="imgs/icon-decoration.png" alt="">
                  <span>{{item.askContent}}</span>
                </span>
              </div>
            </div>
          </div>

          <div class="pagenation">
            <el-pagination
              background
              layout="total, slot, prev, pager, next, slot"
              :current-page="currentPage"
              :page-size="pageSize"
              :total="total"
              @current-change="currentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="提交问题"
      :visible.sync="dialogVisible"
      width="30%"
      top="30vh">
      <el-form ref="form" :model="form" label-width="100px">
        <-item label="问题描述：">
          <el-input v-model="form.content"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getQuestions, reportQuestion } from '../../api/aboutUs'
export default {
  data() {
    return {
      types: [
        {name: '公司简介', type: 0},
        {name: '联系我们', type: 1},
        {name: '组织架构', type: 2},
        {name: '互动提问', type: 3},
      ],
      currentIndex: 0,
      currentType: {},
      msgs: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      form: {},
      company: window.pageConfiguration.company
    }
  },
  computed: mapGetters(['userInfo']),
  watch: {
    $route: {
      handler: function() {
        let index = Number(this.$route.query.index);
        this.changeType(this.types[index], index)
      },
      immediate: true
    }
  },
  methods: {
    changeType(item, index) {
      // 切换类型
      this.currentIndex = index;
      this.currentType = item;
      this.$router.push({name: 'aboutUs', query: {index: index}})
      if(index == 3) {
        this.initQuestions();
      } else {
        this.msgs = []
      }
    },
    async initQuestions() {
      // 获取问题列表
      let params = {
        current: this.currentPage,
        size: this.pageSize
      }
      let res = await getQuestions(params);
      let {code, data} = res;
      if(code == 0) {
        this.msgs = data.records;
        this.total = data.total;
      }
    },
    currentChange(val) {
      this.currentPage = val;
      this.initQuestions()
    },
    question() {
      this.dialogVisible = true;
    },
    async submit() {
      let params = {
        username: this.userInfo.userName,
        content: this.form.content
      }
      let res = await reportQuestion(params);
      let {code,data,msg} = res;
      if(code == 0) {
        this.$message.success('提交成功！');
        this.dialogVisible = false;
        this.initQuestions();
      } else {
        this.$message.error(msg);
      }
    },
    goDetail(item) {
      // 查看详情
      this.$router.push({name: 'questionDetail', query: {c: item.id}})
    }
  }
}
</script>

<style lang="less" scoped>
.aboutUs {
  // background: #fff;
  padding-top: 20px;
  padding-bottom: 70px;
  .s-title {
    position: relative;
    .ques {
      position: absolute;
      bottom: 10px;
      right: 10px;
      height: 34px;
      padding: 0 18px;
    }
  }
  .docs {
    // height: 640px;
    margin-bottom: 8px;
    // overflow-y: auto;
    // margin-top: 25px;
    background: #fff;
    padding: 10px;
    min-height: 500px;
    .docs-item {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 56px;
      color: #333333;
      padding-right: 8px;
      margin-bottom: 14px;
      border-bottom: 1px solid #c9c9c9;
      cursor: pointer;
      img {
        margin-right: 9px;
      }
    }
    .profile {
      padding: 48px 20px;
      border: none;
    }
  }

  .connect {
    padding-top: 50px;
    margin: 0 auto;
    background: #fff;
    min-height: 500px;
    .cen {
      // width: 250px;
      width: 600px;
      margin: 0 auto;
      text-align: center;
    }
    .code {
      width: 100%;
      text-align: center;
      img {
        width: 150px;
        height: 150px;
        margin-left: 30px;
      }
    }
  }
}
</style>
